<template>
  <div id="candidate-layout">
    <KSubMenu
      :menu-items="menuItems"
      :parent-menu="parentMenu"
    />
    <RouterView
      v-if="candidate"
      :candidate="candidate"
      :get-candidate="getCandidate"
    />
  </div>
</template>

<script>

import Candidate from '@/application/models/Candidate.js';
import KSubMenu from '@/components/menu/KSubMenu.vue';
import { show } from '@/modules/candidate/api/candidate.js';
import { mapGetters } from 'vuex';

export default {
  name: 'CandidateLayout',
  components: {
    KSubMenu,
  },
  data: () => ({
    candidate: null,
  }),
  computed: {
    ...mapGetters({ can: 'authorisation/can' }),
    menuItems() {
      const items = [
        {
          title: this.$t('global.dossier'),
          route: { name: 'candidate.show' },
        },
        {
          title: this.$t('candidate.tabs.personal'),
          route: { name: 'candidate.personalDetails' },
        },
        {
          title: this.$tc('document.title', 2),
          route: { name: 'candidate.documents' },
        },
        {
          title: this.$t('candidate.tabs.wishes'),
          route: { name: 'candidate.wishes' },
        },
        {
          title: this.$t('candidate.tabs.background'),
          route: { name: 'candidate.background' },
        },
        {
          title: this.$t('candidate.tabs.admin'),
          route: { name: 'candidate.admin' },
        },
        {
          title: this.$tc('contract.title', 2),
          route: { name: 'candidate.contract' },
          permission: 'contract.show',
        },
        {
          title: this.$tc('placement.title', 2),
          route: { name: 'candidate.placement' },
          permission: 'placement.show',
        },
      ];
      if (this.candidate?.isZzp) {
        items.push({
          title: this.$tc('candidate.tabs.company', 2),
          route: { name: 'candidate.selfEmployment' },
        }, {
          title: this.$t('candidate.tabs.invoices'),
          route: { name: 'candidate.invoices' },
        });
      }
      return items;
    },
    parentMenu() {
      return [
        {
          title: this.$tc('candidate.title', 1),
          route: { name: 'candidate.index' },
        },
      ];
    },
  },
  watch: {
    '$route.params.candidateId': {
      immediate: true,
      async handler() {
        await this.getCandidate();
      },
    },
  },
  methods: {
    async getCandidate() {
      const response = await show(parseInt(this.$route.params.candidateId));
      this.candidate = new Candidate().mapResponse(response.data.data);
    },
  },
};
</script>
